@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@700&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}



/*
Media queries       | Defined   | Range
---------------------------------------------
X-Small             | V         | <576px
---------------------------------------------
Small               | V         | ≥576px
---------------------------------------------
Medium              | V         | ≥768px
---------------------------------------------
Large               | V         | ≥992px
---------------------------------------------
Extra large         | V         | ≥1200px
---------------------------------------------
Extra extra large   | V         | ≥1400px
*/

/* X-Small */
@media screen and (max-width: 576px) {
  :root {
    font-size: 40%;
  }
}

/* Small */
@media screen and (min-width: 576px) {
  :root {
    font-size: 50%;
  }
}

/* Medium */
@media screen and (min-width: 768px) {
  :root {
    font-size: 50%;
  }
}

/* Large */
@media screen and (min-width: 992px) {
  :root {
    font-size: 50%;
  }
}

/* Extra large */
@media screen and (min-width: 1200px) {
  :root {
    font-size: 62.5%;
  }
}

/* Extra extra large */
@media screen and (min-width: 1400px) {
  :root {
    font-size: 75%;
  }
}

body {
  font-family: 'Lexend Deca', sans-serif;

  overflow: hidden;
}

/* Border-box boilerplate that still allows padding-box and content-box */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

